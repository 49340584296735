import { Component, DestroyRef, inject, OnDestroy, OnInit } from "@angular/core";
import { CustomerInformationComponent } from "@root/shared/customer-card/components/customer-card-details/components/customer-information/customer-information.component";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { MatButtonToggle } from "@angular/material/button-toggle";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonComponent } from "@root/shared/button/button.component";
import { UsersService } from "@root/data/market/users/services/users.service";
import { LanguageDropdownComponent } from "@root/shared/language-dropdown/language-dropdown.component";
import { ILanguage } from "@root/shared/interfaces/language.interface";
import { CustomerCardDetailsFormControls } from "@root/shared/customer-card/components/customer-card-details/customer-card-details.form-controls";
import { markControlAsTouchedAndDirty, updateNestedControlsPathAndValue } from "@root/shared/utilities/form.utilities";
import { LegalEntityService } from "@root/data/market/legalEntities/services/legal-entity.service";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { IAddressLookUp } from "@root/shared/interfaces/address-look-up.interface";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { constructInitialAddressLookups } from "@root/shared/utilities/address.utilities";
import { LookupsService } from "@root/data/market/lookups/services/lookups.service";
import { DatepickerComponent } from "@root/shared/datepicker/datepicker.component";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { forkJoin, Observable, Subscription, tap } from "rxjs";
import { CustomerCategoryService } from "@root/data/market/system-configurations/services/customer-category.service";
import { ICustomerDetails } from "@root/data/market/legalEntities/models/customer-details.model";
import { ICustomerCategoryLookUp } from "@root/data/market/system-configurations/customer-category/models/customer-category-look-up.model";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { ICountry } from "@root/shared/interfaces/country.interface";
import { IDemographicQuestionLookUp } from "@root/data/market/demographic-questions/models/demographic-question-look-up.model";
import { BaseCustomerCardTabComponentComponent } from "@root/shared/abstracts/base-customer-card-tab-component/base-customer-card-tab-component.component";
import { IPhoneNumber } from "@root/shared/interfaces/phone-number.interface";
import { DemographicQuestionsService } from "@root/data/market/demographic-questions/services/demographic-questions.service";

@Component({
  selector: "est-customer-card-details",
  standalone: true,
  imports: [
    CustomerInformationComponent,
    SmallHeaderComponent,
    SelectFieldComponent,
    MatButtonToggle,
    MatSlideToggle,
    TranslateModule,
    ButtonComponent,
    LanguageDropdownComponent,
    ReactiveFormsModule,
    DatepickerComponent,
    LoadingOverlayComponent,
  ],
  templateUrl: "./customer-card-details.component.html",
  styleUrl: "./customer-card-details.component.scss",
})
export class CustomerCardDetailsComponent extends BaseCustomerCardTabComponentComponent implements OnInit, OnDestroy {
  readonly #demographicQuestionService = inject(DemographicQuestionsService);
  protected formControls = inject(CustomerCardDetailsFormControls);
  protected demographicQuestionLookUps: IDemographicQuestionLookUp[] = [];
  protected customerCategoryOptions: IDropdownOption[] = [];
  protected languageOptions: ILanguage[] = [];
  protected countries: ICountry[] = [];
  protected addressLookUps: IAddressLookUp<IDropdownOption> = constructInitialAddressLookups();
  protected isPageLoading: boolean = false;
  readonly #customerCategoryService = inject(CustomerCategoryService);
  readonly #legalEntityService = inject(LegalEntityService);
  readonly #lookUpsService = inject(LookupsService);
  readonly #userService = inject(UsersService);
  readonly #destroyRef = inject(DestroyRef);
  #customerDetailsSubscription = new Subscription();

  ngOnInit() {
    this.isPageLoading = true;
    this.#subscribeToFavourite();
    this.#customerDetailsSubscription = forkJoin([
      this.#getAddressLookUps(),
      this.#getLanguages(),
      this.#getCountries(),
      this.#getCustomerCategories(),
      this.#getDemographicQuestions(),
    ]).subscribe({
      next: () => this.#getCustomerDetails().subscribe(() => (this.isPageLoading = false)),
    });
  }

  ngOnDestroy() {
    this.#customerDetailsSubscription.unsubscribe();
  }

  patchCustomerCardDetails() {
    if (
      this.formControls.phone.touched &&
      this.arePhoneControlsDirty(this.formControls.phone, this.formControls.formGroup) &&
      this.formControls.phone.valid
    ) {
      this.#mapPhoneControlToCustomerDetails();
      this.#markPhoneControlsInParentAsTouchedAndDirty();
    }
    this.#sendPatchRequest();
  }

  onReloadRequest() {
    this.isPageLoading = true;
    this.#getCustomerDetails().subscribe(() => (this.isPageLoading = false));
  }

  arePhoneControlsDirty = (phoneControl: FormControl<IPhoneNumber>, parentControl: FormGroup): boolean => {
    if (!phoneControl.value) return false;
    const { number: newNumber, countryCode: newCountryCode } = phoneControl.value;
    const { phoneNumber: oldNumber, phoneIsdCode: oldCountryCode } = parentControl.value;
    const isNumberDirty = newNumber !== oldNumber;
    const isCountryCodeDirty = newCountryCode !== oldCountryCode;
    return isNumberDirty || isCountryCodeDirty;
  };

  #getAddressLookUps(): Observable<IAddressLookUp<IDropdownOption>> {
    return this.#lookUpsService.getAddressLookupOptions().pipe(tap((lookUps) => (this.addressLookUps = lookUps)));
  }

  #getLanguages(): Observable<ILanguage[]> {
    return this.#userService.getAllLanguages().pipe(tap((languages) => (this.languageOptions = languages)));
  }

  #getCountries(): Observable<ICountry[]> {
    return this.#lookUpsService.getAllCountries().pipe(tap((countries) => (this.countries = countries)));
  }

  #getDemographicQuestions(): Observable<IDemographicQuestionLookUp[]> {
    return this.#demographicQuestionService
      .lookUpDemographicQuestions(this.sourceLevel(), this.routeId())
      .pipe(tap((demographicQuestions) => (this.demographicQuestionLookUps = demographicQuestions)));
  }

  #getCustomerCategories(): Observable<ICustomerCategoryLookUp[]> {
    return this.#customerCategoryService
      .lookUpCustomerCategories({
        sourceLevel: this.sourceLevel(),
        routeId: this.routeId(),
        ids: { legalEntityId: this.legalEntityId().toString() },
      })
      .pipe(
        tap(
          (customerCategoryLookUps: ICustomerCategoryLookUp[]) =>
            (this.customerCategoryOptions = customerCategoryLookUps.map<IDropdownOption>((lookUp) => {
              return {
                label: lookUp.value,
                value: lookUp.id,
              };
            })),
        ),
      );
  }

  #getCustomerDetails(): Observable<ICustomerDetails> {
    return this.#legalEntityService.getCustomerDetails(this.legalEntityId()).pipe(
      tap((customerDetails) => {
        this.formControls.phone.patchValue({
          internationalNumber: customerDetails.phoneNumber,
          number: customerDetails.phoneNumber,
          countryCode: customerDetails.phoneIsdCode,
        });
        this.formControls.isFavourite.patchValue(customerDetails.isFavourite, { emitEvent: false });
        this.formControls.formGroup.controls.preferredLanguageId.patchValue(
          customerDetails.preferredLanguage.languageId!,
        );
        this.formControls.formGroup.controls.customerCategoryId.patchValue(
          customerDetails.customerCategory?.id ?? null,
        );
        this.formControls.formGroup.patchValue(customerDetails);
        this.#handleDemographicQuestionAnswerControls(customerDetails);
      }),
    );
  }

  #subscribeToFavourite() {
    this.formControls.isFavourite.valueChanges.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(() => {
      this.#legalEntityService.favouriteCustomer(this.legalEntityId()).subscribe();
    });
  }

  #handleDemographicQuestionAnswerControls(customerDetails: ICustomerDetails) {
    this.formControls.formGroup.controls.demographicQuestionAnswers.clear();
    for (const demographicQuestionAnswer of customerDetails.demographicQuestionAnswers) {
      const matchingQuestion = this.formControls.formGroup.controls.demographicQuestionAnswers.controls.find(
        (x) => x.controls["questionId"].value === demographicQuestionAnswer.questionId,
      );
      if (matchingQuestion) {
        if (matchingQuestion.controls["answerOptionIds"].value instanceof Array) {
          matchingQuestion.controls["answerOptionIds"].setValue([
            ...matchingQuestion.controls["answerOptionIds"].value,
            demographicQuestionAnswer.answerOptionId,
          ]);
        } else {
          matchingQuestion.controls["answerOptionIds"].setValue([
            matchingQuestion.controls["answerOptionIds"].value,
            demographicQuestionAnswer.answerOptionId,
          ]);
        }
      } else {
        this.formControls.formGroup.controls.demographicQuestionAnswers.push(
          new FormGroup({
            id: new FormControl<number>(demographicQuestionAnswer.id!),
            questionId: new FormControl<number>(demographicQuestionAnswer.questionId),
            textAnswer: new FormControl<string>(demographicQuestionAnswer.textAnswer),
            answerOptionIds: new FormControl<number | number[]>(demographicQuestionAnswer.answerOptionId),
          }),
        );
      }
    }
  }

  #markPhoneControlsInParentAsTouchedAndDirty() {
    markControlAsTouchedAndDirty(this.formControls.formGroup.controls["phoneNumber"]);
    markControlAsTouchedAndDirty(this.formControls.formGroup.controls["phoneIsdCode"]);
  }

  #mapPhoneControlToCustomerDetails() {
    this.formControls.formGroup.patchValue({
      phoneNumber: this.formControls.phone.value?.number,
      phoneIsdCode: this.formControls.phone.value?.countryCode,
    });
  }

  #sendPatchRequest() {
    const controlPaths = updateNestedControlsPathAndValue(this.formControls.formGroup);
    if (Object.keys(controlPaths).length) {
      this.#legalEntityService.patchCustomerDetails(this.legalEntityId(), controlPaths).subscribe();
    }
  }

  loadData(): void {}
}
