<div class="flex flex-col gap-6">
  <div class="flex gap-6">
    <div class="p-6 max-lg:pt-8 flex-grow basis-0 rounded-lg bg-black-50 border border-black-300 relative">
      <est-small-header
        appliedClasses="text-xl"
        heading="CUSTOMER_CARD.OVERVIEW.CUSTOMER_HIGHLIGHTS"
        tooltip="CUSTOMER_CARD.OVERVIEW.CUSTOMER_HIGHLIGHTS"
      ></est-small-header>
      <div class="flex gap-3 absolute top-4 right-4 items-start">
        <p class="text-sm font-light">{{ "FAVOURITES" | translate }}</p>
        <est-heart-button [control]="favouriteControl()"></est-heart-button>
      </div>
      <div class="flex gap-[3.5rem] text-sm">
        <div class="flex flex-col gap-4">
          <div class="flex flex-col gap-2">
            <p class="font-medium">{{ "FIRST_NAME" | translate }}</p>
            <p class="font-light">{{ customerOverview().firstName }}</p>
          </div>
          <div class="flex flex-col gap-2">
            <p class="font-medium">{{ "PHONE" | translate }}</p>
            <p class="font-light">{{ customerOverview().phoneIsdCode }} {{ customerOverview().phoneNumber }}</p>
          </div>
          <div class="flex flex-col gap-2">
            <p class="font-medium">{{ "BIRTHDAY" | translate }}</p>
            <p class="font-light">
              {{ customerOverview().birthday | customDate }}
              @if (customerOverview().birthday) {
                <span class="opacity-50">({{ customerOverview().age }} {{ "YEARS" | translate | lowercase }})</span>
              }
            </p>
          </div>
          <div class="flex flex-col gap-2">
            <p class="font-medium">{{ "NATIONALITY" | translate }}</p>
            <p class="font-light">{{ customerOverview().nationality }}</p>
          </div>
        </div>
        <div class="flex flex-col gap-4">
          <div class="flex flex-col gap-2">
            <p class="font-medium">{{ "LAST_NAME" | translate }}</p>
            <p class="font-light">{{ customerOverview().lastName }}</p>
          </div>
          <div class="flex flex-col gap-2">
            <p class="font-medium">{{ "EMAIL" | translate }}</p>
            <p class="font-light">{{ customerOverview().email }}</p>
          </div>
          <div class="flex flex-col gap-2">
            <p class="font-medium">{{ "COUNTRY" | translate }}</p>
            <p class="font-light">{{ customerOverview().country }}</p>
          </div>
          <div class="flex flex-col gap-2">
            <p class="font-medium">{{ "PREFERRED_LANGUAGE" | translate }}</p>
            <p class="font-light">{{ customerOverview().preferredLanguage }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="p-6 max-lg:pt-8 flex-grow basis-0 rounded-lg bg-black-50 border border-black-300">
      <est-small-header
        appliedClasses="text-xl"
        heading="CUSTOMER_CARD.OVERVIEW.REGISTRATION_DETAILS"
        tooltip="CUSTOMER_CARD.OVERVIEW.REGISTRATION_DETAILS"
      ></est-small-header>
      <div class="flex-col flex gap-6 text-sm">
        <div class="flex text-sm flex-col gap-2">
          <p class="font-medium">{{ "FIRST_CREATION_DATE" | translate }}</p>
          <p class="font-light">{{ customerOverview().createdAt | customDate: true }}</p>
        </div>
        <div class="flex text-sm flex-col gap-2">
          <p class="font-medium">{{ "CUSTOMERS.LATEST_ACTIVITY_ON_CUSTOMER" | translate }}</p>
          <p class="font-light">{{ customerOverview().updatedAt | customDate: true }}</p>
        </div>
        <div class="flex text-sm flex-col gap-2">
          <p class="font-medium">{{ "CUSTOMERS.LATEST_PRIVACY_CONFIRMATION" | translate }}</p>
          <p class="font-light">
            {{ "CONFIRMED" | translate }}: {{ customerOverview().latestPrivacyConfirmationDate | customDate: true }}
          </p>
        </div>
        <div class="flex text-sm flex-col gap-2">
          <p class="font-medium">{{ "CUSTOMERS.LATEST_MARKETING_CONFIRMATION" | translate }}</p>
          <p class="font-light">
            {{ "CONFIRMED" | translate }}: {{ customerOverview().latestMarketingConfirmationDate | customDate: true }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="p-6 w-full h-full rounded-lg bg-black-50 border border-black-300">
    <est-small-header
      appliedClasses="text-xl"
      heading="CUSTOMERS.CUSTOMER_JOURNEYS"
      tooltip="CUSTOMERS.CUSTOMER_JOURNEYS"
    ></est-small-header>
    <div class="flex-col flex">
      <est-small-list-table
        [isLoading]="isTableLoading"
        [reversedColours]="true"
        [data]="paginatedData.results()"
        [headers]="headers"
        [sortingFilterSignal]="sortingFilterSignal"
      ></est-small-list-table>
      <est-pagination [paginationValues]="paginatedData"></est-pagination>
    </div>
  </div>
</div>
